import * as actionTypes from './actionTypes';
import Cookies from 'js-cookie';
import moment from 'moment-timezone';

import { removeItem, saveUserInfo, getUserInfo, saveCognitoTokens } from '../services/localStorage';
import { handleSignOut } from 'services/amplify/handleSignOut';
import { validateLocation } from 'containers/CustomerManagement/Customers/utils';
import { queryClient } from 'queryClient';
import History from '../history';
import { isGuestEnv } from 'utils/envUtils';
import { getSessionDetailsImperatively } from 'api/getSessionDetailsImperatively';
import { returnIsOnboardStatus } from 'helpers/returnIsOnboardStatus';
import { TORO_HQ_COORDINATES } from 'constants.js';

const updateLocalStorage = updatedInfo => {
  const userInfo = JSON.parse(getUserInfo());
  saveUserInfo({ ...userInfo, ...updatedInfo });
};

const getGeoCodedCompanyCoords = async info => {
  const address = {
    address: `${info.AddressLine1}, ${info.City}, ${info.State} ${info.PostalCode}, USA`
  };
  const data = await validateLocation(address);

  return {
    companyAddressCoordinates: data.lat
      ? {
          lat: data.lat,
          lng: data.lng
        }
      : {
          lat: TORO_HQ_COORDINATES.lat,
          lng: TORO_HQ_COORDINATES.lng
        },
    Latitude: data?.lat || TORO_HQ_COORDINATES.lat,
    Longitude: data?.lng || TORO_HQ_COORDINATES.lng
  };
};

/**
 * @function <b>authSuccess</b><br>
 * @param {Object} userInfo 'User information which we get in response.'
 * @param {String} token 'Auth Token'
 * @returns {dispatch}
 */

export const authSuccess = data => {
  try {
    // if (data.SessionToken) {
    if (data.cognitoTokens) {
      // TODO: use isAuthenticated instead? maybe the same thing really?
      // console.log('idfff');
      window['emailTrace'] = data.EmailAddress || '';
      if (data.Timezone) {
        moment.tz.setDefault(data.Timezone);
      }
      // saveToken(data.SessionToken);
      saveCognitoTokens(data.cognitoTokens);
      const userInfo = {
        ...data,
        isAdmin: data.IsAdmin || 0,
        loggedAsAdmin: data.IsAdmin || 0,
        companyId: data.CompanyId,
        userId: data.UserId,
        firstName: data.FirstName ? data.FirstName : 'Admin',
        lastName: data.LastName ? data.LastName : '',
        avatarUrl: data.AvatarUrl,
        companyName: data.CompanyName,
        showAppTour: data.IsOnboarding === undefined ? undefined : !data.IsOnboarding,
        StartWorkHour: data.StartWorkHour || '09:00:AM',
        EndWorkHour: data.EndWorkHour || '05:00:PM',
        Holidays: Boolean(data.Holidays),
        Weekends: Boolean(data.Weekends),
        StartOfWeek: data.StartOfWeek || 'Sunday',
        EmailAddress: data.EmailAddress || '',
        Timezone: data.Timezone,
        tutorialSection: data.TutorialSectionSeen,
        IsCompanyVerified: data.IsCompanyVerified || 0,
        SubscriptionStatusId: data.SubscriptionStatusId || 1,
        HasCompanyDetails: data.HasCompanyDetails,
        subscriptionDetails: data.Subscription,
        companyAddressCoordinates: data.Latitude
          ? {
              lat: data.Latitude,
              lng: data.Longitude
            }
          : undefined,
        accessibleSections: data.AccessibleSections,
        numberOfCreditCards: data.NumberOfCreditCards,
        DisplayName: data.DisplayName,
        Sender: data.Sender,
        isAuthenticated: true,
        Quickbooks: Boolean(data?.QuickbooksAccessToken && data?.QuickbooksRefreshToken),
        countBossDevices: data.CountBossDevices
      };
      saveUserInfo(userInfo);
      Cookies.set('userId', data.UserId);
      return {
        ...data,
        type: actionTypes.AUTH_SUCCESS,
        // token: data.SessionToken,
        cognitoTokens: {
          accessToken: data.cognitoTokens.accessToken,
          idToken: data.cognitoTokens.idToken
        },
        isAuthenticated: true,
        isAdmin: data.IsAdmin || 0,
        companyId: data.CompanyId,
        loggedAsAdmin: data.IsAdmin || 0,
        userId: data.UserId,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        avatarUrl: data.AvatarUrl,
        companyName: data.CompanyName,
        showAppTour: data.IsOnboarding === undefined ? undefined : !data.IsOnboarding,
        StartWorkHour: data.StartWorkHour || '09:00:AM',
        EndWorkHour: data.EndWorkHour || '05:00:PM',
        Holidays: Boolean(data.Holidays),
        Weekends: Boolean(data.Weekends),
        StartOfWeek: data.StartOfWeek || 'Sunday',
        EmailAddress: data.EmailAddress || '',
        Timezone: data.Timezone,
        tutorialSection: data.TutorialSectionSeen,
        IsCompanyVerified: data.IsCompanyVerified || 0,
        SubscriptionStatusId: data.SubscriptionStatusId || 1,
        HasCompanyDetails: data.HasCompanyDetails,
        subscriptionDetails: data.Subscription,
        companyAddressCoordinates: data.Latitude
          ? {
              lat: data.Latitude,
              lng: data.Longitude
            }
          : undefined,
        accessibleSections: data.AccessibleSections,
        numberOfCreditCards: data.NumberOfCreditCards,
        DisplayName: data.DisplayName,
        Sender: data.Sender,
        Quickbooks: Boolean(data?.QuickbooksAccessToken && data?.QuickbooksRefreshToken),
        countBossDevices: data.countBossDevices
      };
    }
  } catch (error) {
    throw error;
  }
};

// On Operator's name changed.

export const onNameChange = (firstName, lastName) => {
  const userInfo = JSON.parse(getUserInfo());
  const firstLetter = firstName.toLowerCase()[0];
  const avatarUrl = `${userInfo.avatarUrl && userInfo.avatarUrl.slice(0, -5)}${firstLetter}.png`;
  const updatedUserInfo = {
    ...userInfo,
    firstName,
    lastName: lastName,
    avatarUrl
  };
  saveUserInfo(updatedUserInfo);
  return {
    type: actionTypes.NAME_CHANGE,
    firstName,
    lastName,
    avatarUrl
  };
};
export const onTimezoneChange = newTimezone => {
  const userInfo = JSON.parse(getUserInfo());
  const updatedUserInfo = {
    ...userInfo,
    Timezone: newTimezone
  };
  saveUserInfo(updatedUserInfo);
  return {
    type: actionTypes.CHANGE_TIME_ZONE,
    Timezone: newTimezone
  };
};

export const handleCalendarDateRangeChangeRedux = ({ calendarStartDate, calendarEndDate }) => {
  return {
    type: actionTypes.UPDATE_SCHEDULE_CALENDAR_DATE_RANGE,
    calendarStartDate,
    calendarEndDate
  };
};
//update the redux state with the changes tutorial data
export const updateTutorialSeenState = updatedTutorial => {
  const userInfo = JSON.parse(getUserInfo());
  const updatedUserInfo = {
    ...userInfo,
    tutorialSection: updatedTutorial
  };
  saveUserInfo(updatedUserInfo);
  return {
    type: actionTypes.UPDATE_TUTORIAL_STATE,
    tutorialSection: updatedTutorial
  };
};

//To toggle values for showing APP Tour
export const appTourStatus = showAppTour => {
  const userInfo = JSON.parse(getUserInfo());
  const updatedUserInfo = {
    ...userInfo,
    showAppTour
  };
  saveUserInfo(updatedUserInfo);
  return {
    type: actionTypes.APP_TOUR_STATUS,
    showAppTour
    // On Manage Work Hours changed.
  };
};
export const onManageWorkHours = data => {
  const userInfo = JSON.parse(getUserInfo());
  const updatedUserInfo = {
    ...userInfo,
    ...data
  };
  saveUserInfo(updatedUserInfo);
  return {
    type: actionTypes.MANAGE_WORK_HOURS,
    data
  };
};

//ADMIN_AS_OPERATOR

export const adminAsOperator = (companyId, companyName) => async dispatch => {
  //calling the company details api to store necessary info to the redux store.
  try {
    const {
      data: {
        Company: data,
        Subscription,
        Miscellaneous,
        AccessibleSections,
        Evo,
        TutorialSectionSeen
      }
    } = await getSessionDetailsImperatively(companyId);
    const {
      StartWorkHour,
      EndWorkHour,
      Holidays,
      Weekends,
      StartOfWeek,
      Timezone,
      CompanyOwner,
      DisplayName
    } = data;

    const { NumberOfCreditCards, CountBossDevices } = Miscellaneous;

    if (Timezone) {
      moment.tz.setDefault(Timezone);
    }

    const info = {
      ...data,
      companyId,
      companyName,
      isAdmin: 0,
      StartWorkHour,
      EndWorkHour,
      StartOfWeek,
      Holidays: Boolean(Holidays),
      Weekends: Boolean(Weekends),
      Timezone,
      companyOwnerId: CompanyOwner.UserId,
      subscriptionDetails: Subscription,
      Evo,
      IsCompanyVerified: data.IsVerified || 0,
      SubscriptionStatusId: data.SubscriptionStatusId || 1,
      companyAddressCoordinates: data.Latitude
        ? {
            lat: data.Latitude,
            lng: data.Longitude
          }
        : undefined,
      accessibleSections: AccessibleSections,
      numberOfCreditCards: NumberOfCreditCards,
      DisplayName: DisplayName,
      Sender: data.Sender,
      Quickbooks: Boolean(data?.QuickbooksAccessToken && data?.QuickbooksRefreshToken),
      countBossDevices: CountBossDevices,
      tutorialSection: TutorialSectionSeen
    };
    if (!info.companyAddressCoordinates) {
      const { companyAddressCoordinates, Latitude, Longitude } = await getGeoCodedCompanyCoords(
        info
      );

      info.companyAddressCoordinates = companyAddressCoordinates;
      info.Latitude = Latitude;
      info.Longitude = Longitude;
    }
    updateLocalStorage(info);
    return dispatch({
      type: actionTypes.ADMIN_AS_OPERATOR,
      data: info
    });
  } catch (error) {
    throw error;
  }
};

// Admin (logged as operator) switch backs to Admin

export const becomeAdmin = () => {
  const userInfo = JSON.parse(getUserInfo());

  const data = {
    companyId: 0,
    isAdmin: 1,
    StartWorkHour: '09:00:AM',
    EndWorkHour: '05:00:PM',
    StartOfWeek: 'Sunday',
    Holidays: true,
    Weekends: true,
    companyName: '',
    companyOwnerId: null,
    IsCompanyVerified: 0,
    subscriptionDetails: null,
    Timezone: moment.tz.setDefault(moment.tz.guess()), //set the time to device/browser timezone since it is being viewed by the super admin,
    tutorialSection: null,
    companyAddressCoordinates: null
  };

  const updatedUserInfo = {
    ...userInfo,
    ...data
  };
  saveUserInfo(updatedUserInfo);
  sessionStorage.clear();
  return {
    payload: {
      ...data
    },
    type: actionTypes.OPERATOR_AS_ADMIN
  };
};

export const companyVerified = () => {
  const userInfo = JSON.parse(getUserInfo());
  const updatedUserInfo = {
    ...userInfo,
    IsCompanyVerified: 1
  };
  saveUserInfo(updatedUserInfo);
  return {
    payload: {
      IsCompanyVerified: 1
    },
    type: actionTypes.COMPANY_VERIFIED
  };
};
export const companyDetailsAdded = () => {
  const userInfo = JSON.parse(getUserInfo());
  const updatedUserInfo = {
    ...userInfo,
    HasCompanyDetails: true
  };
  saveUserInfo(updatedUserInfo);
  return {
    payload: {
      HasCompanyDetails: true
    },
    type: actionTypes.COMPANY_DETAILS_ADDED
  };
};
export const changeSubscriptionStatus = SubscriptionStatusId => {
  const userInfo = JSON.parse(getUserInfo());
  const updatedUserInfo = {
    ...userInfo,
    SubscriptionStatusId
  };
  saveUserInfo(updatedUserInfo);
  return {
    type: actionTypes.CHANGE_SUBSCRIPTION_STATUS,
    SubscriptionStatusId
  };
};
export const updateSubscriptionObject = subscriptionDetails => {
  const userInfo = JSON.parse(getUserInfo());
  const updatedUserInfo = {
    ...userInfo,
    subscriptionDetails
  };
  saveUserInfo(updatedUserInfo);
  return {
    type: actionTypes.SUBSCRIBED_SUCCESS,
    subscriptionDetails
  };
};
export const updateCompanyAddressCoordinates = newCoordinates => {
  if (!newCoordinates) {
    return;
  }
  const userInfo = JSON.parse(getUserInfo());
  const updatedUserInfo = {
    ...userInfo,
    companyAddressCoordinates: newCoordinates
  };
  saveUserInfo(updatedUserInfo);
  return {
    type: actionTypes.UPDATE_COMPANY_COORDINATES,
    companyAddressCoordinates: newCoordinates
  };
};
/**
 *
 * @param {number} companyId
 * @description It will update the details stored in LS as well as Redux
 */
export const refreshDetails = companyId => async dispatch => {
  try {
    const {
      data: {
        Company: data,
        Subscription,
        Miscellaneous,
        AccessibleSections,
        User,
        Operator,
        Employee,
        Evo,
        TutorialSectionSeen
      }
    } = await getSessionDetailsImperatively(companyId);

    const {
      StartWorkHour,
      EndWorkHour,
      Holidays,
      Weekends,
      StartOfWeek,
      Timezone,
      DisplayName,
      CompanyOwner
    } = data;
    const { NumberOfCreditCards, CountBossDevices } = Miscellaneous;

    if (Timezone) {
      moment.tz.setDefault(Timezone);
    }
    const userInfo = JSON.parse(getUserInfo());
    const firstName = User.RoleId === 1 ? CompanyOwner.FirstName : User.FirstName;
    const lastName = User.RoleId === 1 ? CompanyOwner.FirstName : User.LastName;
    const info = {
      ...data,
      companyId,
      StartWorkHour,
      EndWorkHour,
      StartOfWeek,
      Holidays: Boolean(Holidays),
      Weekends: Boolean(Weekends),
      firstName: firstName ? firstName : 'Admin',
      lastName: lastName ? lastName : '',
      Timezone,
      companyOwnerId: CompanyOwner.UserId,
      subscriptionDetails: Subscription,
      Evo,
      IsCompanyVerified: data.IsVerified || 0,
      SubscriptionStatusId: data.SubscriptionStatusId || 1,
      companyAddressCoordinates: data.Latitude
        ? {
            lat: data.Latitude,
            lng: data.Longitude
          }
        : undefined,
      showAppTour: userInfo?.loggedAsAdmin
        ? false
        : !returnIsOnboardStatus({
            roleId: User.RoleId,
            companyOwner: CompanyOwner,
            operator: Operator,
            employee: Employee
          }), //  stop setting showAppTour to true for admin created non onboarded companies when Super admin has logged in
      accessibleSections: AccessibleSections,
      numberOfCreditCards: NumberOfCreditCards,
      DisplayName: DisplayName,
      Sender: data.Sender,
      Quickbooks: Boolean(data?.QuickbooksAccessToken && data?.QuickbooksRefreshToken),
      countBossDevices: CountBossDevices,
      tutorialSection: TutorialSectionSeen
    };

    if (!info.companyAddressCoordinates) {
      const { companyAddressCoordinates, Latitude, Longitude } = await getGeoCodedCompanyCoords(
        info
      );

      info.companyAddressCoordinates = companyAddressCoordinates;
      info.Latitude = Latitude;
      info.Longitude = Longitude;
    }
    updateLocalStorage(info);
    return dispatch({
      type: actionTypes.REFRESH_DETAILS,
      data: info
    });
  } catch (error) {
    throw error;
  }
};

//LOGOUT
export const authLogout =
  ({ redirect = true, employeeLogout } = {}) =>
  // for some pages like the timeclock, we cannot rely only on the login api to tell us if the user is authorised to access the page
  // in such cases,
  // i have to read the user role and log out an user if required
  // or the session api throws 400 -> unauthorised (session api is called after a user is authenticated)
  // in such cases the redirect prop will come handy.
  // when you are in timeclock -> i expect to receive redirect as false or else, user will be needlessly redirected to /login
  // cannot rely on Histoy.path
  async dispatch => {
    try {
      await handleSignOut();
      window['emailTrace'] = undefined;
      queryClient.removeQueries(); // remove query cache completely
      removeItem();
      Cookies.remove('userId');
      if (employeeLogout) {
        History.push('/timeclock');
      } else if (redirect) isGuestEnv() ? History.push('/login/guest') : History.push('/login');
      return dispatch({
        type: actionTypes.AUTH_LOGOUT
      });
    } catch (error) {
      throw error;
    }
  };

// export const handleCalendarDateRangeRedux = ({startDate, endDate}) => {
//   return
// }
