import { queryClient } from 'queryClient';
import { getSessionDetails } from './getSessionDetails';

export const getSessionDetailsImperatively = async companyId => {
  return await queryClient.fetchQuery({
    queryKey: [
      'SESSION_DETAILS_IMPERATIVE',
      {
        companyId
      }
    ],
    queryFn: () => getSessionDetails({ companyId })
  });
};
