import { red, neutral, green, blue, orange, yellow, lightGrey } from './colors';

export const theme = {
  primary: red[100],
  errorRed: red[200],
  neutralPrimary: neutral[100],
  textPrimary: neutral[100],
  textSecondary: neutral[200],
  textFaded: neutral[300],
  pageBackground: neutral[400],
  pageBackgroundWhite: neutral[800],
  textInverted: neutral[800],
  disabled: neutral[300],
  buttonText: neutral[800],
  alternativeText: neutral[500],
  success: green[100],
  selection: blue[100],
  inputBackground: neutral[700],
  emptyStateWarning: neutral[500],
  lightBorder: neutral[600],
  emptyStateWarningLg: neutral[600],
  iconGrey: neutral[900],
  hoveredBackground: neutral[1000],
  disabledField: neutral[1100],
  mutedHeading: neutral[600],
  batteryLow: red[400],
  batteryMedium: orange[400],
  batteryFull: green[200],
  lightText: neutral[600],
  iconDivider: neutral[1200],
  grey666666: neutral[900],
  red_700: red[700],
  yellow_400: yellow[400],
  disabledFieldBackground: lightGrey[100]
};
