import APIUtil from 'api/apiUtil';
import appConfig from '../../config';
const api = new APIUtil();
const api_v2 = new APIUtil(appConfig.config().baseUrl_v2);
const SignupAPI = {
  createUser(data) {
    return api.post('company/user', data, undefined, 'v5');
  },
  companyEdit(companyId, data, config) {
    return api_v2.put(`company/${companyId}?billing=true`, data, config, 'v2');
  },
  verifyCaptcha(Token) {
    return api_v2.post(`user/verify_captcha`, { Token }, undefined, 'v2');
  },
  fetchBillingAddress(companyId) {
    return api.get(`company/${companyId}/payments/billingAddress`, undefined, 'v4');
  },
  fetchBillingDetails(companyId, planId = 1, config) {
    return api.get(`company/${companyId}/billingInfo/${planId}`, config, 'v4');
  },
  subscribeCompany(companyId, data) {
    return api.post(`company/${companyId}/subscribe`, data, undefined, 'v4');
  },
  updateBillingAddress(companyId, addressId, data) {
    return api.put(`company/${companyId}/payments/address/${addressId}`, data);
  },
  fetchPaymetricDetails(companyId) {
    return api_v2.get(`company/${companyId}/payments/accesstoken`, undefined, 'v1');
  },
  fetchPlans(companyId, config) {
    return api.get(`company/${companyId}/plan`, config, 'v4');
  },
  sendLog(companyId, data) {
    return api.post(`user/${companyId}/log`, data, undefined, 'v2');
  },
  checkPlanValidityWithAddons(companyId, planId, config) {
    return api.get(`company/${companyId}/CheckPlanPrice/${planId}`, config, 'v4');
  }
};
export default SignupAPI;
