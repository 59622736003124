import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClientProvider } from 'react-query';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App.jsx';
import { createStore, applyMiddleware, compose } from 'redux';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import {
  getTokenFromLocalStorage,
  getUserInfo,
  isUserAuthenticated
} from './services/localStorage';
import reduxThunk from 'redux-thunk';
import rootReducer from './reducers';
import configureAxios from './api/axiosUtility';
import { Router } from 'react-router-dom';
import history from './history';
import moment from 'moment-timezone';
import RenderIf from 'containers/Render_If/index.jsx';
import { Amplify } from 'aws-amplify';

import { returnAmplifyConfig, returnBucketNames } from 'helpers/returnAmplifyConfig.js';
import { queryClient } from 'queryClient.js';

/**
 * The v5 configuration object was the first to work and still works
 * The configuration object I have eventually implemented is the latest v6 implementation.
 * The documentation has no provision for region and identityPoolId though.
 * My guess is amplify figures out the region based on the userpoolid and
 * identityPoolId is something i have added - purely through guesswork.
 * TODO: Remove out the (working) v5 configuration method code once the feature is QA tested and stable
 */
// v5 configuration - works with v6 as well
// Amplify.configure({
//   aws_cognito_region: awsExports.AWS_COGNITO_REGION,
//   aws_user_pools_id: isGuestEnv()
//     ? awsExports.AWS_USER_POOLS_ID_GUEST
//     : awsExports.AWS_USER_POOLS_ID,
//   aws_user_pools_web_client_id: isGuestEnv()
//     ? awsExports.AWS_USER_POOLS_WEB_CLIENT_ID_GUEST
//     : awsExports.AWS_USER_POOLS_WEB_CLIENT_ID,
//   cognito_identity_pool_id: isGuestEnv()
//     ? awsExports.AWS_IDENTITY_POOL_ID_GUEST
//     : awsExports.AWS_IDENTITY_POOL_ID
// });

// v6 configuration
Amplify.configure(
  {
    Auth: {
      Cognito: {
        userPoolClientId: returnAmplifyConfig().userPoolClientId,
        userPoolId: returnAmplifyConfig().userPoolId,
        identityPoolId: returnAmplifyConfig().identityPoolId
      }
    },
    Storage: {
      S3: {
        bucket: returnBucketNames(),
        region: 'us-east-1'
      }
    }
  },
  {
    Storage: {
      S3: {
        // isObjectLockEnabled: true //OPTIONAl - Object Lock parameter
        prefixResolver: async ({ accessLevel, targetIdentityId }) => {
          return '';
        }
      }
    }
  }
);

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const enhancer = composeEnhancers(
  applyMiddleware(reduxThunk)

  // other store enhancers if any
);
const userInfo = JSON.parse(getUserInfo());
let authTokens = undefined;
if (isUserAuthenticated()) {
  authTokens = getTokenFromLocalStorage();
}
// if user is logged in and page is being refreshed, set the default timezone to the company timezone
if (userInfo && userInfo?.isAuthenticated) {
  moment.tz.setDefault(userInfo.Timezone);
}
export const store = createStore(
  rootReducer,
  {
    auth: {
      ...userInfo,
      isAuthenticated: isUserAuthenticated() ? true : false,
      // token: authTokens,
      cognitoTokens: authTokens
        ? {
            accessToken: authTokens.accessToken,
            idToken: authTokens.idToken
          }
        : undefined,
      isAdmin: userInfo ? userInfo.isAdmin : null,
      loggedAsAdmin: userInfo ? userInfo.loggedAsAdmin : null,
      companyId: userInfo ? userInfo.companyId : null,
      userId: userInfo ? userInfo.userId : null,
      firstName: userInfo ? userInfo.firstName : '',
      lastName: userInfo ? userInfo.lastName : '',
      avatarUrl: userInfo ? userInfo.avatarUrl : '',
      companyName: userInfo ? userInfo.companyName : '',
      showAppTour: userInfo ? userInfo.showAppTour : false,
      StartWorkHour: userInfo ? userInfo.StartWorkHour : '09:00:AM',
      EndWorkHour: userInfo ? userInfo.EndWorkHour : '05:00:PM',
      Holidays: userInfo ? userInfo.Holidays : true,
      Weekends: userInfo ? userInfo.Weekends : true,
      StartOfWeek: userInfo ? userInfo.StartOfWeek : 'Sunday',
      EmailAddress: userInfo ? userInfo.EmailAddress : '',
      Timezone: userInfo ? userInfo.Timezone : '',
      companyOwnerId: userInfo ? userInfo.companyOwnerId : null,
      tutorialSection: userInfo ? userInfo.tutorialSection : null,
      IsCompanyVerified: userInfo ? userInfo.IsCompanyVerified : null,
      SubscriptionStatusId: userInfo ? userInfo.SubscriptionStatusId : null,
      subscriptionDetails: userInfo ? userInfo.subscriptionDetails : null,
      companyAddressCoordinates: userInfo ? userInfo.companyAddressCoordinates : null,
      accessibleSections: userInfo ? userInfo.accessibleSections : null,
      numberOfCreditCards: userInfo ? userInfo.numberOfCreditCards : null,
      DisplayName: userInfo ? userInfo.DisplayName : null,
      Sender: userInfo ? userInfo.Sender : '',
      calendarStartDate: null,
      calendarEndDate: null,
      Quickbooks: Boolean(userInfo?.QuickbooksAccessToken && userInfo?.QuickbooksRefreshToken)
    }
  },
  enhancer
);
window['emailTrace'] = userInfo ? userInfo.EmailAddress : '';
configureAxios(store);

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <QueryClientProvider client={queryClient}>
        <App />
        <RenderIf isTrue={process.env.REACT_APP_STAGE !== 'production'}>
          <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
        </RenderIf>
      </QueryClientProvider>
    </Router>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
