import { currentSession } from './amplify/currentSession';
import { isUserAuthenticated, saveCognitoTokens } from './localStorage';

/**
 * @returns {Object} if the user is authenticated - an object with {idToken, accessToken}
 * @returns {undefined} if user is not authenticated
 * @description the function is an asynchronous function which calls currentSession which has its try catch block
 */
export async function getToken() {

  if (isUserAuthenticated()) {
    // const sessionInfo = await currentAuthenticatedUser();
    // if (sessionInfo) {
    // don't do this on the user information page (when user hasn't signed up/signed in)
    const { idToken, accessToken } = await currentSession();
    // updating the local storage as well to prevent discrepencies in case of tokens being refreshed
    saveCognitoTokens({ idToken, accessToken });
    return { idToken, accessToken };
    // }
  }

  return undefined;
}