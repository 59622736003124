export const capitaliseFirstChar = string => {
  if (!string?.length) return null;
  return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
};

export const capitalizeKeys = data => {
  const updatedData = {};
  for (const [key, value] of Object.entries(data)) {
    const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
    updatedData[capitalizedKey] = value;
  }
  return updatedData;
};
