import axios from 'axios';
import config from '../config';
import { authLogout } from '../actions/auth';
import History from '../history';
import { isGuestEnv } from 'utils/envUtils';
import { getToken } from 'services/currentSession';

// Add a request interceptor
const configureAxios = store => {
  axios.interceptors.request.use(
    async function (config) {
      //This is for the notifications. We are calling the getstream.io and wee pass seprate header to that api .
      //To avoid toro token overiding getstream token, we check Stream-Auth-Type
      if (config.headers['Stream-Auth-Type']) {
        return config;
      }
      // adding token to the authorization header only
      const tokens = await getToken();
      if (tokens) {
        config.headers = {
          'cognito-access-token': tokens.accessToken,
          'cognito-id-token': tokens.idToken
        };
      }
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      // Do something with response data
      return response;
    },
    function (error) {
      // Do something with response error
      // check if the config url has notification
      // to avoid the app breaking
      if (
        error &&
        error.response &&
        error.response.config &&
        (error.response.config.url.includes(`${config.config().baseUrl}/v4/notification`) ||
          error.response.config.url.includes(`${config.config().baseUrl}/v4/logging/frontend`))
      ) {
        return Promise.reject(error);
      }

      if (error.response && parseInt(error.response.status, 10) === 401) {
        if (
          error.response.config.url === `${config.config().baseUrl}/v1/user/login` ||
          error.response.config.url === `${config.config().baseUrl}/v1/user/logout` ||
          error.response.config.url.includes(
            `${config.config().baseUrl}/v1/company/employeeLogin`
          ) ||
          error.response.config.url.includes('/quickBookConnectionState')
        ) {
          return Promise.reject(error);
        }

        store.dispatch(authLogout());

        throw error;
      }
      if (error.response && parseInt(error.response.status, 10) === 500) {
        History.push('/error-500');
      }
      if (error.response && parseInt(error.response.status, 10) === 403) {
        if (isGuestEnv()) {
          store.dispatch(authLogout());
        } else {
          History.push('/error-403');
        }
      }
      if (error.response && parseInt(error.response.status, 10) === 404) {
        if (error.response.config.url === `${config.config().baseUrl}/v1/user/forgot-password`) {
          return Promise.reject(error);
        }
        History.push('/error-404');
      }

      return Promise.reject(error);
    }
  );
};

export default configureAxios;
